@tailwind base;
@tailwind components;
@tailwind utilities;

/* Additional custom styles */

/* Dark mode specific styles */
.dark .bg-gray-100 {
  background-color: #1f2937; /* Dark mode background */
}

.dark .text-gray-800 {
  color: #e5e7eb; /* Dark mode text color */
}

.dark .text-gray-100 {
  color: #e5e7eb; /* Dark mode text color */
}

.dark .border-gray-300 {
  border-color: #4a5568; /* Dark mode border color */
}

.transition-colors {
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Responsive dark mode for mobile */
@media (max-width: 640px) {
  .dark .container {
    background-color: #1f2937; /* Dark mode background on mobile */
  }
}

/* Add space between buttons */
.button-spacing {
  margin-right: 8px; /* Adjust the value to control the spacing */
}

.button-spacing:last-of-type {
  margin-right: 0; /* Remove right margin for the last button */
}

.geo-bg {
  background-image: url('./geo.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

